<template>

	<div>

		<b-modal v-model="addnoaccesstolist" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Restore access and add?</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnoaccesstolist = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> You are adding a user that has previously had their access to the organization revoked, Adding them will restore their access to the organization as well as any boards and objectives they previously had access to. Do you wish to proceed?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "userselectwarningproceed()"></button-gruppe>
	        </template>
        </b-modal> 

		<b-modal v-model="showteamdeletemessage" id ="approvalchanges" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title" v-if="showteamdeletemessagetype==0">Confirm team deletion</h3>
	          <h3 class="modal-title" v-if="showteamdeletemessagetype==1">The team cannot be deleted</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="showteamdeletemessage = false"></i>
	          </a>
	        </template>
	        <template v-if="showteamdeletemessagetype==0">
	        	<p>Deleting this team will remove access to any boards or objectives the team currently has access to. If any team member still needs to access any of those resources, then they will need to be added individually or through another team.</p></template>
	        <template v-if="showteamdeletemessagetype==1">
	        	<p>The team you want to delete is the only owner of the following boards or objectives:</p>
	        	<ul>
	        	<li v-for="item in showteamdeletemessageitems.boards" :key="item._id">{{ item.name}}</li>
	        	</ul>
				<p>You need to add other owners to these boards or objectives before the team can be deleted</p>
	        </template>

	        

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe v-if="showteamdeletemessagetype==0" :btnclass="'btn-danger'" text1= "Delete team" @no ="cancel" @yes="deletethisteamconfirm"></button-gruppe>
	          <button-gruppe v-if="showteamdeletemessagetype==1" :btnclass="'btn-danger'" text1= "Ok" @no ="cancel" @yes="cancel"></button-gruppe>
	        </template>
        </b-modal>


	<b-modal v-model="confirmselfremove" id ="approvalchanges" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Confirm</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="confirmselfremove = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> This action will 
	        remove you as an owner and you will immediately lose access to this settings page.

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes="doconfirmselfremove"></button-gruppe>
	        </template>
        </b-modal>

	<i v-if="!teams && !team" class="fa fa-circle-o-notch fa-spin"></i>

	<div v-if="teams && !team">

	<div class="mb-3 ">

		<form onsubmit="return false" v-if="editorstate.mode=='trycreate'" class="text-right">
		<div class="mb-2">
		<input class="form-control" type="text" v-model="teamname" ref="newteaminput" @input="teamnameinput()"> 
		</div>
  	  	<button class="ml-1 btn btn-primary" :class="{'mt-2':windowspec=='sm' }" @click="createnewteam()" type="submit">Create team</button> <button class="btn btn-secondary" :class="{'mt-2':windowspec=='sm' }" @click="editorstate.mode='idle'">Cancel</button>
  	  	<span class="text-danger" v-if="editorstate.name.error=='teamnamerequired'">Team name is required</span>
  	  	</form>

  	  	<button type="button" v-if="editorstate.mode=='idle'" class="btn btn-secondary" @click="initcreatenewteam()">Create new team</button>

	</div>

	<memberslist 
		
		@onexpandme="expandme2"
		:readonlysubitems="readonlysubitems"
		:users="teams" 
		:listtype="'listofteams'"
		:username="username"
		:adminrole="adminrole"
		:orgkey="orgkey"
		:team="team"/>
	</div>	


	<div v-if="team && ( myrole == adminrole ) " >

	<router-link class="pl-0 btn btn-link" :to="'/'+orgkey+'/organizations/teams/#'"> <button type="button" class="btn btn-secondary"><i class="fal fa-angle-double-left" aria-hidden="true"></i><span class="pl-2">Teams overview</span></button></router-link>

	<advancedinput class ="mt-2" label = "Team name" :myrole ="team.myrole" :editorstate ="editorstate" :textvalue="team.name"  @no="canceltitle()" @yes="savename()" @click="opentitle()"/>


	<div data-state="idle" class="form-group mt-2">
		<label>Members</label>



	<memberslist 
		@onswitchrole="switchrole"
		@onremovefromteam="removefromteam"
		@oninviteuser ="inviteuser" 
		:users="teamusers" 
		:listtype="'membersperteam'"
		:username="username"
		:adminrole="adminrole"
		:orgkey="orgkey"
		:myrole="myrole"
		:team="team"
		:numowners="numowners"/>
	
	<membersearch 
	 	@onupdated="onupdated" 
	 	@availableusersupdate="availableusersupdate" 
	 	@userSelect ="userSelect" 
	 	@oninviteuser ="inviteuser" 
	 	@onsearchactive="searchactive"
	 	:users = "users" 
	 	:availableusers="availableusers" 
	 	:alreadyonlist ="alreadyonlist"
	 	:sendaninvite="sendaninvite"
	 	:isactive ="isactive" 
	 	:allowadduser="allowadduser" 
	 	:adminrole="adminrole"  
	 	:myrole="myrole" 
	 	:isadmanaged="isadmanaged"
	 	:username="username" 
	 	:searchtext="searchtext"
	 	/>

	 </div>


	 

	<div class="mt-3">
		<button class="mr-1 btn btn-danger-secondary" @click="deletethisteam(team._id,false)" v-if="confirmdelete==false">Delete this team</button> 
		<button class="mr-1 btn btn-danger-secondary" @click="deletethisteam(team._id,false)" v-if="confirmdelete==true">Confirm delete team</button> 
		<button class="btn btn-secondary" @click="confirmdelete=false" v-if="confirmdelete==true">Cancel</button>
	</div>

	</div>

</div>

</template>

<style lang = "scss" >
	#memberlislorg{
			#table-objusers{
				.tablerow:first-child{
					border: none;
				}
			}
		}
</style>
<script type="text/javascript"></script>

<script>

	import memberslist from '@/components/members/memberslist' 
	import membersearch from '@/components/members/membersearch'
	import advancedinput from '@/components/advanced/input-with-pencil'

	import Teamsapi from '@/services/api/teams'
 	import Cockpitsapi from '@/services/api/cockpits'
	import Userapi from '@/services/api/users'
 //	import Cockpitsapi from '@/services/api/cockpits'
 	import Emailsapi from '@/services/api/emails'

	import {bus} from '@/main'
	export default{
		name: "teams",
		data(){
			return{
				readonlysubitems:true,
				popupusersshown:true,
				activeteam : {},
				editorstate: {mode: 'idle', name:{content:'',state: 'idle', error:''}, description: {content:'', state: 'idle'}},
				newteammodal:true,
				 roles:[ { label:"Administrator", value:"Administrator" } , { label:"Member", value:"Member" } , { label:"Disabled", value:"Disable" } ],
				 adminrole: "Owner",
				 defaultrole: "Member",
				 //teams:[],
				 selectedteam:null,
				 teamname:"",
				 users: [],
				 availableusers:[],
				 alreadyonlist:false,
				 sendaninvite :false,
				 sendaninvitetouser:"",
				 isactive:true,
				 isadmanaged:false,
				 page:"teams",
				 updateduser:{},
				 confirmdelete:false,
				 confirmselfremove:false,
				 tempuser:{ user:{}, payload: {}, teamid:null },
				 removeact:"",
				 showteamdeletemessage:false,
				 showteamdeletemessagetype:0,
				 showteamdeletemessagetitle:"",
				 showteamdeletemessagebody:"",
				 showteamdeletemessageitems:[],
				 btnclass:"btn-danger",
				 addnoaccesstolist:false,
				 activeoptionselected:null,
				 searchtext:""
			}
		},

		watch:{
		    $route (to, from){      

		       var teamid = this.$route.params.teamid

		       if( teamid ){
		       	this.getteams(teamid)
		       }else{
		       	this.getteams()
		       }
		       //alert(teamid)
		       //this.teams = null
		       //this.team = null
		       

		    }
		},

		mounted(){

			var teamid = this.$route.params.teamid


			if( teamid ){
		       	this.getteams(teamid)
		    }else{
		    	this.getteams()
		    }
			
			this.$forceUpdate(); 
		},
		methods: {

			historyback(){f

				this.$router.push('/'+this.orgkey+'/boards/' + this.cockpitid + '/objectives'  )

			},

			teamnameinput(){
				this.editorstate.name.error = ''
			},

			initcreatenewteam(){
				
				this.editorstate.mode='trycreate'
				//console.log(this.$refs.newteaminput)
				this.$nextTick(function() {
					this.$refs.newteaminput.focus()
				})
			},

			deletethisteamconfirm(){

				if( this.tempuser.teamid ){
					this.deletethisteam( this.tempuser.teamid, true )
				}
				
			},

			deleteteammessage( msgtype, payload ) {

				this.showteamdeletemessagetype = msgtype
				this.showteamdeletemessageitems = payload
		        this.showteamdeletemessage = true
		      },

			deletethisteam( teamid, confirm ){

				var toconfirm = false

				if( confirm ){
					toconfirm = confirm
				}

				let self = this

				self.tempuser.teamid = null


				Teamsapi.Deleteteam( this.$store.getters.token, teamid, confirm, self.orgkey ).then( function( response ){

					if( response.data.ok ){

						//Teamsapi.Updateteamuserstatus( teamid, self.orgkey )

						//self.getteams(teamid)

						let userids = self.teamuserids

						let timer = 0

						userids.forEach( async function( userid ){

							setTimeout( function(){
								Teamsapi.Updateuserstatus( userid, self.orgkey, 'removefromteam' )
							}, timer )

							timer += 100
								
						})

						

						setTimeout( function(){

							self.showteamdeletemessage = false
							self.$router.push('/'+self.orgkey+'/organizations/teams/'  )

						}, 100)

						

						// location.href='/'+ self.orgkey+'/organizations/teams/'
						
					}else{

						self.tempuser.teamid = teamid

						if( response.data.errormessage == "soleowner" ){
							self.deleteteammessage( 1, { "boards":response.data.boards, "objectives":response.data.objectives, "teamid":teamid } )
						}

						if( response.data.errormessage == "needsconfirmation" ){
							self.deleteteammessage(0, { "teamid":teamid } )
						}
					}

					//self.getteams()
					//self.$router.push('/'+self.orgkey+'/organizations/teams/'  )

				})

				/*if( !confirm ){

					//this.showMsgBoxOne()

					
					Teamsapi.Deleteteam( this.$store.getters.token, teamid, false ).then( function(){


						//self.getteams()
						//self.$router.push('/'+self.orgkey+'/organizations/teams/'  )

					})

				}else{

					Teamsapi.Deleteteam( this.$store.getters.token, teamid ).then( function(){


						self.getteams()
						self.$router.push('/'+self.orgkey+'/organizations/teams/'  )

					})

				}*/


				

				

			},

			doconfirmselfremove(){

				if( this.removeact == "selfremove" ){
					this.switchrole( this.tempuser.user, this.tempuser.payload, true )
				}

				if( this.removeact == "selfdelete" ){
					this.removefromteam( this.tempuser.user, this.tempuser.payload, true )
				}

				//
				//this.switchrole( this.tempuser.user, this.tempuser.payload, true )
			},

			switchrole( user, payload, confirm ){

				var toconfirm = false

				if( !confirm ){
					toconfirm = false
				}else{
					toconfirm = confirm
				}
				//alert('xxx')

				//console.log( this.$store.getters.token, payload.team._id, user._id, payload.role )

				/*

	this.boxOne = ''
		        this.$bvModal.msgBoxConfirm( msg1, { title: title1 } )
		          .then(value => {
		            this.boxOne = value
		          })
		          .catch(err => {
		            // An error occurred
		          })
		          Warning: This action will remove you as an owner and you will immediately lose access to this settings page. If you proceed and later on regret removing yourself, then you will need to ask another owner to add you as owner again. 
				*/

				let self = this

				this.tempuser.user = {}
				this.tempuser.payload = {}

				this.removeact = "selfremove"

				Teamsapi.Setrole( this.$store.getters.token, payload.team._id, user._id, payload.role, toconfirm ).then( function( response ){

					if( response.data && response.data.ok ){

						if( response.data.isselfremoval ){

							//Teamsapi.Updateteamuserstatus( self.team._id, self.orgkey )

							self.confirmselfremove = false
							self.$router.push('/'+self.orgkey+'/organizations/teams/'  )
		         			//location.href='/'+ self.orgkey+'/organizations/teams/'

						}else{
							self.getteams( payload.team._id )
						}
						
					}else{

						

						self.tempuser.user = user
						self.tempuser.payload = payload

						if( response.data.errormessage == "notifyselfremoval" ){
								self.confirmselfremove = true
							}
					}

					//confirmselfremove


					//

				})

			},

			expandme2( user ){


				if( user.expanded == true ){
					user.expanded = false
				}else{
					user.expanded = true
				}


				this.updateduser = user
			},

			removefromteam( user, team, confirm ){

				var toconfirm = false

				if( !confirm ){
					toconfirm = false
				}else{
					toconfirm = confirm
				}

				let self = this

				this.tempuser.user = {}
				this.tempuser.payload = {}

				this.removeact = "selfdelete"

				Teamsapi.Removeuser( this.$store.getters.token, team._id, user._id, toconfirm ).then( function(response){


					if( response.data && response.data.ok ){

						if( response.data.isselfremoval ){							

							self.confirmselfremove = false
							self.$router.push('/'+self.orgkey+'/organizations/teams/'  )

							//location.href='/'+ self.orgkey+'/organizations/teams/'
			         
						}else{
							self.getteams(team._id)
						}

						Teamsapi.Updateuserstatus( user._id, self.orgkey, 'remove' )
						
					}else{

						

						self.tempuser.user = user
						self.tempuser.payload = team

						if( response.data.errormessage == "notifyselfremoval" ){
								self.confirmselfremove = true
							}
					}

					

				})

			},

			username(user){
				let username = ''
	          	username += user.firstname ? user.firstname:'';
	          	username += user.lastname ? ` ${user.lastname}`:'';
	          	username += !username && user.email ? user.email:'';

	          	return username
			},
			

			searchactive(){

				/*var teamusers = Teamsapi.Getusers( this.$store.getters.token, this.team._id )
				teamusers.then( function( response ){

					console.log( response )
				})*/
				console.log('searchactive')
			},

			onupdated(){
				console.log('onupdated')
			}, 
			availableusersupdate( search, loading ){

		        let self = this

		        this.alreadyonlist = false

		        if( this.searchbounce ){
		          clearTimeout(this.searchbounce)
		        }

		        this.searchbounce = setTimeout( function(){

		          if( search ){
		            self.searchquery = search
		          }        

		          if( search && search.length > 1 ){

		            loading(true)


		            Cockpitsapi.Getusers( search, self.exemptids, self.orgkey, false )
		            .then( function(response){      

		              if( response.data ){

		                self.sendaninvite = response.data.sendaninvite
		                self.alreadyonlist = response.data.alreadyonlist

		                if( self.sendaninvite == true ){
		                  self.sendaninvitetouser = response.data.inviteuser
		                }                

		                self.userexists =  response.data.userexists   

		                self.availableusers = response.data.users

		                 self.availableusers.find(item => item._id === self.userexists._id).userexist = true


		              //console.log(self.availableusers)            
		            }

		          })
		            .catch( function(){

		              //self.cockpitsettingsmodalshown = false
		            })
		            .finally( () => {
		              loading(false)
		              //self.ajustlengthdown()

		             //ajustlengthup
		           })

		          }else{

		            self.availableusers = []
		            //self.ajustlengthdown()
		            self.searchquery = ""

		          }

		        }, 500)
		        

		      }, 

		      inviteuser(isresend , user ){
   	

			   	 let self = this

		         var email = this.sendaninvitetouser

		         var str1 = 'Invite sent'

		         if( isresend ){

		              var str = ""
		              //Emailsapi.Invitationemail( user, email, context)
		              Emailsapi.Invitationemail( self.loggedinuser._id, user.email, self.orgkey)

		              /*
		              We have re-sent the invitation email to the address you indicated. Once they sign up, they will automatically have the accesses you have indicated in the members list.*/
		              str = "We have sent an invitation to the email address you indicated."
					  

		              /*setTimeout( function(){
		                $('.vs__search')[0].blur()
		              }, 100)*/

		               		               
		              bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

		         }else{		         

		         	Userapi.Registeruser( email, this.$store.getters.token , this.orgkey ).then( function(response){

			            
			            if( response.data.ok ){

			              self.userSelect( response.data.user , true, true )

			              var str = ""

			              Emailsapi.Invitationemail( self.loggedinuser._id, email, self.orgkey)

			              str = "We have sent an invitation to the email address you indicated."
						  

			             /* if( $('.vs__search')[0] ){

			              	setTimeout( function(){
			                $('.vs__search')[0].blur()
			              	}, 100)

			              }*/
			              

			               
			               
			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})
			                

			            }else{

			              var str = "Failed to send an invitation email to the address you indicated."

			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})

			            }
			        }).catch( function( err ){

			        	console.log("rollback")

			        })

		      	}

		         
		    },

		    userselectwarningproceed(){

		    	this.addnoaccesstolist = false
		    	this.userSelect( this.activeoptionselected , true )

		    },


			userSelect( option , confirm ){
				//console.log('userSelect', option.orgrole)

				//this.activeoptionselected = option


				//if( option.orgrole == "Disable" && !confirm ){
				//	this.addnoaccesstolist = true
				//}else{

					this.activeoptionselected = null

					let self = this

					Teamsapi.Adduser( this.$store.getters.token, this.team._id, option._id ).then( function( response ){
						

						Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
								self.getteams(response.data.team._id)					
						})

					})


				//}


			}, 
			readyupdate( team ){

				this.activeteam = team
				//bus.$emit('initmemberslist', team.sharedtousers)
				this.teamupdate('name', team.name )

				this.activeteam = {}
			},

			updateend( team ){

				this.activeteam = {}

			},

			editteam( team , el ){

				//console.log(  )

				if( this.activeteam._id == team._id ){

					if( el.target.value ){

					}else{
						this.activeteam = {}
					}
					

				}else{

				this.activeteam = team

				var el = el.target
				

				setTimeout( function(){

					if( el.nextSibling ){
						el.nextSibling.focus()
					}
					

				}, 100 )


				}



				/*
				
				


				let self = this

				 //this.$refs.email.$el.focus();

				 consol

				

				*/
				

			},

			getteams( teamid ){

				//console.log(teamid)

				/*if(!teamid){
					teamid
				}*/


				let self = this

				var fetchteams = this.$store.dispatch('fetchAllTeams',{ token:this.$store.getters.token, orgkey:this.orgkey, teamid:teamid })

					fetchteams.then( function( response ){

					//console.log( response.data, "response")

					//console.log( self.$store.getters.teams.teams, self.$store.getters.teams, "getall")

					//self.teams = self.$store.getters.teams





					if( self.team ){
					
						self.editorstate.name.content = self.team.name
						var team = self.$store.getters.team

						//alert('')

						//userids
						//this.selectedteam = team
						//bus.$emit('initmemberslist', team.sharedtousers)

					}
					
					
					//this.$store.state.teams.allteams

					//store.getters.teams.teams
					//bus.$emit('initmemberslist',self.organization.sharedtousers)
				}) 

			},

			teamdetails(){


			},

			opentitle(){
				if(this.editorstate.description.state == 'idle'){
					
					this.editorstate.name.state = 'editing'

					/*this.$nextTick(function() {
						setTimeout(()=>{
							$('#cockpittitle').focus()
						},100)
					})*/
				}
			},

			canceltitle(){
				this.editorstate.name.state='idle'; 
				this.editorstate.name.content = this.team.name
			},

			newteammodalhidden(){},
			newteammodalshown(){
				 this.teamname = "" 
			},

			selectteam( team ){

				this.selectedteam = team
				//bus.$emit('initmemberslist', team.sharedtousers)

			},

			savename(){

				this.activeteam = this.team

				this.team.name = this.editorstate.name.content
				this.teamupdate('name', this.editorstate.name.content)
				this.editorstate.name.state='idle'
			},

			createnewteam(){

				if( this.teamname != "" ){


			        let self = this

			        this.$store.dispatch('createTeam', { "name": this.teamname , "context": this.orgkey, "t": this.$store.getters.token}).then(res =>{

			          self.newteammodal = false		
			          //self.teams = res.data.teams   

			          self.teamname = "" 

			          self.editorstate.mode='idle'

			          self.$router.push('/'+self.orgkey+'/organizations/teams/'+ res.data.team._id   )  

			          //location.href='/'+ self.orgkey+'/organizations/teams/' + res.data.team._id 

			        })

				}else{

					this.editorstate.name.error = "teamnamerequired"
				}

			},

			teamupdate(field, value, callback){
				var newvalue = value

				let self = this


				this.$store.dispatch('updateTeams', {field:field, newvalue:newvalue, context:this.orgkey, teamid:this.activeteam._id ,t:this.$store.getters.token}).then((response) =>{

					self.activeteam = {}
				})

			},

			usersupdated( field, value ){

				
				var sharedtousers = []

				for( var i in value ){
					sharedtousers.push( { "userid":value[i].id, "role":value[i].role, "status":value[i].status } )
				}


				this.teamupdate('sharedtousers', JSON.stringify( sharedtousers ), function( data ){

					//console.log( data )
				})
				
				//this.editorstate.name.state='idle'
			},

			/*saveusers(){
				this.organization.name = this.editorstate.name.content
				this.organizationupdate('name', this.editorstate.name.content)
				this.editorstate.name.state='idle'
			},*/
		},
		computed: {

			hashistory(){

				return this.$route.query.history && this.$route.query.history != ''  ? true : false
			},

			cockpitid(){
        		return this.$route.query.history
     		},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}else if( this.windowsize >= 461 && this.windowsize < 768 ){
					winsize = 'md'
				}else{
					winsize = 'lg'
				}

				return winsize

			},

			loggedinuser(){
				return this.$store.getters.user
			},

			numowners(){

				var numowners = 0
				for(var i in this.teamusers){
					if(this.teamusers[i].role.toUpperCase() == this.adminrole.toUpperCase()){
						numowners ++;
					}
				}

				return numowners;
			},

			allowadduser(){

				return this.team.myrole == this.adminrole ? true : false
			},

			myrole(){

				return this.team.myrole
			},

			exemptids(){

				var ids = []
				for( var i in this.team.users ){
					ids.push( this.team.users[i]._id )
				}
				return ids
			},

			teamusers(){

				if( this.team.users ){
					return this.team.users
				}else{
					return []
				}
				

			},

			teamuserids(){

				let userids = []

				if( this.team?.users ){
					for(var i in this.team.users){
						if(this.team.users[i]._id){
							userids.push(this.team.users[i]._id)
						}
					}
				}else{
					userids = []
				}
				
				return userids

			},
			organization(){
				return this.$store.state.organizations.selectedorganizations
			},
			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },
		    team(){
		    	return  this.$store.getters.team ? this.$store.getters.team : null
		    },
			/*cockpitid(){
        		return this.$route.params.cid
     		},*/
		    rawteams(){

		    	return this.$store.getters.teams ? this.$store.getters.teams : []
		    },
		    teams(){

		    	var rawteams = this.$store.getters.teams ? this.$store.getters.teams : []


		    	var newteams = []

		    	if( rawteams.length > 0 ){

		    		for( var i in rawteams ){

		    			var team = { "_id":rawteams[i]._id, "name":"", "firstname":"", "lastname":"", "email":"Team", "status":"active", "myrole":"Member", "expandable":true, "expanded":false, "type":null, "subitems":[] }

		    			team.firstname = rawteams[i].name
		    			team.name = rawteams[i].name
		    			team.subitems = rawteams[i].users
		    			team.profilepic = rawteams[i].profilepic
		    			team.myrole = rawteams[i].myrole
		    			team.type = rawteams[i].type

		    			if( this.updateduser._id == rawteams[i]._id ){
		    				team.expanded = this.updateduser.expanded
		    			}

		    			newteams.push( team )
		    		}
		    	}

		    	return newteams
		    },
		    teamid(){
		    	return  this.$route.params.teamid ? this.$route.params.teamid : null
		    }
		},

		components: {
			memberslist, membersearch, advancedinput
			
		}
	}
</script>